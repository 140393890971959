import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2065d0b6 = () => interopDefault(import('../pages/aboutus.vue' /* webpackChunkName: "pages/aboutus" */))
const _4421f22e = () => interopDefault(import('../pages/cipp-offer/index.vue' /* webpackChunkName: "pages/cipp-offer/index" */))
const _a960c612 = () => interopDefault(import('../pages/help.vue' /* webpackChunkName: "pages/help" */))
const _fc724504 = () => interopDefault(import('../pages/verify-email.vue' /* webpackChunkName: "pages/verify-email" */))
const _0c40462d = () => interopDefault(import('../pages/business/analytics.vue' /* webpackChunkName: "pages/business/analytics" */))
const _85309a06 = () => interopDefault(import('../pages/business/communication.vue' /* webpackChunkName: "pages/business/communication" */))
const _305520c8 = () => interopDefault(import('../pages/business/membersupport.vue' /* webpackChunkName: "pages/business/membersupport" */))
const _43f1ae7e = () => interopDefault(import('../pages/dashboard/combine.vue' /* webpackChunkName: "pages/dashboard/combine" */))
const _4aafb1b7 = () => interopDefault(import('../pages/dashboard/documents.vue' /* webpackChunkName: "pages/dashboard/documents" */))
const _efca8154 = () => interopDefault(import('../pages/dashboard/landing.vue' /* webpackChunkName: "pages/dashboard/landing" */))
const _3e377913 = () => interopDefault(import('../pages/dashboard/nextsteps.vue' /* webpackChunkName: "pages/dashboard/nextsteps" */))
const _6ddc9276 = () => interopDefault(import('../pages/dashboard/report.vue' /* webpackChunkName: "pages/dashboard/report" */))
const _5cd0c2fe = () => interopDefault(import('../pages/dashboard/reports.vue' /* webpackChunkName: "pages/dashboard/reports" */))
const _48c21aec = () => interopDefault(import('../pages/dashboard/retirement.vue' /* webpackChunkName: "pages/dashboard/retirement" */))
const _7aa1658e = () => interopDefault(import('../pages/dashboard/review-choices.vue' /* webpackChunkName: "pages/dashboard/review-choices" */))
const _5bf8012d = () => interopDefault(import('../pages/dashboard/selected-funds.vue' /* webpackChunkName: "pages/dashboard/selected-funds" */))
const _2eb58f02 = () => interopDefault(import('../pages/dashboard/selfhelpdirectory.vue' /* webpackChunkName: "pages/dashboard/selfhelpdirectory" */))
const _0656b3f4 = () => interopDefault(import('../pages/dashboard/settings.vue' /* webpackChunkName: "pages/dashboard/settings" */))
const _12f2dbcc = () => interopDefault(import('../pages/dashboard/shortlist-funds.vue' /* webpackChunkName: "pages/dashboard/shortlist-funds" */))
const _531f84e6 = () => interopDefault(import('../pages/onboarding/annuity-options.vue' /* webpackChunkName: "pages/onboarding/annuity-options" */))
const _2706e03b = () => interopDefault(import('../pages/onboarding/authorization.vue' /* webpackChunkName: "pages/onboarding/authorization" */))
const _00e24e18 = () => interopDefault(import('../pages/onboarding/checkout.vue' /* webpackChunkName: "pages/onboarding/checkout" */))
const _4925ef9e = () => interopDefault(import('../pages/onboarding/employment-info.vue' /* webpackChunkName: "pages/onboarding/employment-info" */))
const _f2434e9c = () => interopDefault(import('../pages/onboarding/fund-selection.vue' /* webpackChunkName: "pages/onboarding/fund-selection" */))
const _b9097daa = () => interopDefault(import('../pages/onboarding/login.vue' /* webpackChunkName: "pages/onboarding/login" */))
const _bc1b9134 = () => interopDefault(import('../pages/onboarding/login-error.vue' /* webpackChunkName: "pages/onboarding/login-error" */))
const _5cd5f92a = () => interopDefault(import('../pages/onboarding/money-purchase-annual-allowance.vue' /* webpackChunkName: "pages/onboarding/money-purchase-annual-allowance" */))
const _1fe8eeb8 = () => interopDefault(import('../pages/onboarding/my-tax-liability.vue' /* webpackChunkName: "pages/onboarding/my-tax-liability" */))
const _3edf05bf = () => interopDefault(import('../pages/onboarding/other-pot-addition.vue' /* webpackChunkName: "pages/onboarding/other-pot-addition" */))
const _295d0b05 = () => interopDefault(import('../pages/onboarding/pension-details.vue' /* webpackChunkName: "pages/onboarding/pension-details" */))
const _d10cd09c = () => interopDefault(import('../pages/onboarding/pension-provider-by-name.vue' /* webpackChunkName: "pages/onboarding/pension-provider-by-name" */))
const _7926f1fc = () => interopDefault(import('../pages/onboarding/pension-savings.vue' /* webpackChunkName: "pages/onboarding/pension-savings" */))
const _0b5ba6dd = () => interopDefault(import('../pages/onboarding/personal-info.vue' /* webpackChunkName: "pages/onboarding/personal-info" */))
const _067f01aa = () => interopDefault(import('../pages/onboarding/redeem-voucher.vue' /* webpackChunkName: "pages/onboarding/redeem-voucher" */))
const _2ba1e9f2 = () => interopDefault(import('../pages/onboarding/reset-password.vue' /* webpackChunkName: "pages/onboarding/reset-password" */))
const _56ac7245 = () => interopDefault(import('../pages/onboarding/success.vue' /* webpackChunkName: "pages/onboarding/success" */))
const _52851966 = () => interopDefault(import('../pages/onboarding/suez-info.vue' /* webpackChunkName: "pages/onboarding/suez-info" */))
const _4e55963c = () => interopDefault(import('../pages/onboarding/tapered-allowance.vue' /* webpackChunkName: "pages/onboarding/tapered-allowance" */))
const _a001f0cc = () => interopDefault(import('../pages/onboarding/tax-charge-period.vue' /* webpackChunkName: "pages/onboarding/tax-charge-period" */))
const _a20c15ea = () => interopDefault(import('../pages/onboarding/tax-payment-methods.vue' /* webpackChunkName: "pages/onboarding/tax-payment-methods" */))
const _3776b0d8 = () => interopDefault(import('../pages/personal/account.vue' /* webpackChunkName: "pages/personal/account" */))
const _a8e83b8a = () => interopDefault(import('../pages/personal/nextsteps.vue' /* webpackChunkName: "pages/personal/nextsteps" */))
const _18d7ae62 = () => interopDefault(import('../pages/personal/retirement.vue' /* webpackChunkName: "pages/personal/retirement" */))
const _31d0c813 = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _43cdf28c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aboutus",
    component: _2065d0b6,
    name: "aboutus"
  }, {
    path: "/cipp-offer",
    component: _4421f22e,
    name: "cipp-offer"
  }, {
    path: "/help",
    component: _a960c612,
    name: "help"
  }, {
    path: "/verify-email",
    component: _fc724504,
    name: "verify-email"
  }, {
    path: "/business/analytics",
    component: _0c40462d,
    name: "business-analytics"
  }, {
    path: "/business/communication",
    component: _85309a06,
    name: "business-communication"
  }, {
    path: "/business/membersupport",
    component: _305520c8,
    name: "business-membersupport"
  }, {
    path: "/dashboard/combine",
    component: _43f1ae7e,
    name: "dashboard-combine"
  }, {
    path: "/dashboard/documents",
    component: _4aafb1b7,
    name: "dashboard-documents"
  }, {
    path: "/dashboard/landing",
    component: _efca8154,
    name: "dashboard-landing"
  }, {
    path: "/dashboard/nextsteps",
    component: _3e377913,
    name: "dashboard-nextsteps"
  }, {
    path: "/dashboard/report",
    component: _6ddc9276,
    name: "dashboard-report"
  }, {
    path: "/dashboard/reports",
    component: _5cd0c2fe,
    name: "dashboard-reports"
  }, {
    path: "/dashboard/retirement",
    component: _48c21aec,
    name: "dashboard-retirement"
  }, {
    path: "/dashboard/review-choices",
    component: _7aa1658e,
    name: "dashboard-review-choices"
  }, {
    path: "/dashboard/selected-funds",
    component: _5bf8012d,
    name: "dashboard-selected-funds"
  }, {
    path: "/dashboard/selfhelpdirectory",
    component: _2eb58f02,
    name: "dashboard-selfhelpdirectory"
  }, {
    path: "/dashboard/settings",
    component: _0656b3f4,
    name: "dashboard-settings"
  }, {
    path: "/dashboard/shortlist-funds",
    component: _12f2dbcc,
    name: "dashboard-shortlist-funds"
  }, {
    path: "/onboarding/annuity-options",
    component: _531f84e6,
    name: "onboarding-annuity-options"
  }, {
    path: "/onboarding/authorization",
    component: _2706e03b,
    name: "onboarding-authorization"
  }, {
    path: "/onboarding/checkout",
    component: _00e24e18,
    name: "onboarding-checkout"
  }, {
    path: "/onboarding/employment-info",
    component: _4925ef9e,
    name: "onboarding-employment-info"
  }, {
    path: "/onboarding/fund-selection",
    component: _f2434e9c,
    name: "onboarding-fund-selection"
  }, {
    path: "/onboarding/login",
    component: _b9097daa,
    name: "onboarding-login"
  }, {
    path: "/onboarding/login-error",
    component: _bc1b9134,
    name: "onboarding-login-error"
  }, {
    path: "/onboarding/money-purchase-annual-allowance",
    component: _5cd5f92a,
    name: "onboarding-money-purchase-annual-allowance"
  }, {
    path: "/onboarding/my-tax-liability",
    component: _1fe8eeb8,
    name: "onboarding-my-tax-liability"
  }, {
    path: "/onboarding/other-pot-addition",
    component: _3edf05bf,
    name: "onboarding-other-pot-addition"
  }, {
    path: "/onboarding/pension-details",
    component: _295d0b05,
    name: "onboarding-pension-details"
  }, {
    path: "/onboarding/pension-provider-by-name",
    component: _d10cd09c,
    name: "onboarding-pension-provider-by-name"
  }, {
    path: "/onboarding/pension-savings",
    component: _7926f1fc,
    name: "onboarding-pension-savings"
  }, {
    path: "/onboarding/personal-info",
    component: _0b5ba6dd,
    name: "onboarding-personal-info"
  }, {
    path: "/onboarding/redeem-voucher",
    component: _067f01aa,
    name: "onboarding-redeem-voucher"
  }, {
    path: "/onboarding/reset-password",
    component: _2ba1e9f2,
    name: "onboarding-reset-password"
  }, {
    path: "/onboarding/success",
    component: _56ac7245,
    name: "onboarding-success"
  }, {
    path: "/onboarding/suez-info",
    component: _52851966,
    name: "onboarding-suez-info"
  }, {
    path: "/onboarding/tapered-allowance",
    component: _4e55963c,
    name: "onboarding-tapered-allowance"
  }, {
    path: "/onboarding/tax-charge-period",
    component: _a001f0cc,
    name: "onboarding-tax-charge-period"
  }, {
    path: "/onboarding/tax-payment-methods",
    component: _a20c15ea,
    name: "onboarding-tax-payment-methods"
  }, {
    path: "/personal/account",
    component: _3776b0d8,
    name: "personal-account"
  }, {
    path: "/personal/nextsteps",
    component: _a8e83b8a,
    name: "personal-nextsteps"
  }, {
    path: "/personal/retirement",
    component: _18d7ae62,
    name: "personal-retirement"
  }, {
    path: "/blog/:slug?",
    component: _31d0c813,
    name: "blog-slug"
  }, {
    path: "/",
    component: _43cdf28c,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
